<template>
  <div>
    <EmailTemplateAdd
      :initial-email-template.sync="emailTemplate"
      :email-template-value.sync="emailTemplate" />
  </div>
</template>

<script>
import EmailTemplateAdd from './components/EmailTemplateAdd.vue'

export default {
  components: {
    EmailTemplateAdd
  },
  data () {
    return {
      emailTemplate: {
        templateName: '',
        subject: '',
        message: '',
        remark: '',
        emailGroupId: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
