<template>
  <div>
    <b-card
      @hidden="resetForm()">
      <template #default="{}">
        <ValidationObserver
          #default="{ handleSubmit }"
          ref="EmailTemplateAddForm">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm()">
            <p class="text--weight">
              Add Email Template
            </p>
            <b-row>
              <b-col
                sm="12"
                md="8">
                <TextFieldInput
                  v-model="emailTemplate.templateName"
                  :rules="{ required: false }"
                  name="Template Name"
                  label="Template Name"
                  placeholder="Template Name"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="8">
                <TextFieldInput
                  v-model="emailTemplate.subject"
                  :rules="{ required: false }"
                  name="Subject"
                  label="Subject"
                  placeholder="Subject"
                  trim />
              </b-col>
              <b-col
                sm="12"
                md="12">
                <b-form-group
                  label="Message">
                  <wysiwyg
                    v-model="emailTemplate.message"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col
                sm="12"
                md="12">
                <b-form-group
                  label="Remark"
                >
                  <wysiwyg
                    v-model="emailTemplate.remark"
                  />
                </b-form-group>
              </b-col>
              <b-col
                sm="12"
                md="8">
                <SelectField
                  v-model="emailTemplate.emailGroupId"
                  :options="emailGroup"
                  :rules="{ required: false }"
                  name="Email Group Id"
                  form-label="Email Group ID"
                  label-option="groupName"
                  return-value="id" />
              </b-col> -->
            </b-row>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class=""
                variant="success"
                @click="addEmailGroup()">
                <span class="align-middle"> Save </span>
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </template>
    </b-card>
  </div>
</template>

<script>
import TextFieldInput from '@/components/Form/TextFieldInput.vue'
// import SelectField from '@/components/Form/SelectField.vue'
import EmailTemplateProvider from '@/resources/EmailTemplateProvider'
import EmailGroupProvider from '@/resources/EmailGroupProvider'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const EmailTemplateService = new EmailTemplateProvider()
const EmailGroupService = new EmailGroupProvider()

export default {
  components: {
    TextFieldInput
    // SelectField
  },
  props: {
    initialEmailTemplate: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      emailGroup: []
    }
  },
  computed: {
    emailTemplate: {
      get () {
        return this.initialEmailTemplate
      },
      set () {
        this.$emit('update:email-template-value')
      }
    }
  },
  async created () {
    try {
      await this.getEmailGroup()
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    resetForm () {
      this.$refs.EmailTemplateAddForm.reset()
    },
    async getEmailGroup () {
      try {
        const { data } = await EmailGroupService.findAll()
        this.emailGroup = data
      } catch (error) {
        console.log(error)
      }
    },
    async addEmailGroup () {
      try {
        const payload = { ...this.emailTemplate }
        await EmailTemplateService.create(payload)
        this.$router.push({ name: 'email-template' })
        this.resetForm()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Void Successfully',
            icon: 'EditIcon',
            variant: 'success',
            text: '👋 SMS Template has been void'
          }
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add Fail',
            icon: 'EditIcon',
            variant: 'danger',
            text: '😞 Email Template has been Failed'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text--weight {
  font-weight: 500 !important;
}
</style>
